import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/jan/1.jpeg";
import im2 from "../../assets/images/jan/2.jpeg";
import im3 from "../../assets/images/jan/3.jpeg";
import { Link } from "react-router-dom";

import jan from "../../assets/music/jan.mp3"; //Jab koi baat bigad jaaye
export default function January() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">January❣️</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to January...</p>
        <p className="month_text_big">
          <i>7 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          Our first new year together darling🥺🥺 (If you count staring at each
          other through a laptop being together.)
        </p>
        <p>
          As being away from each other, got more and more difficult, the one
          hour we got together at the airport on the 6th of January, was the
          best hour of the entire month.
        </p>
        <p>
          In that moment, hugging you goodbye at the airport, all I wanted was
          for that moment to never end because at that time neither of us had
          any idea when we were going to see each other next, I couldnt imagine
          spending another moment without you in my arms.
        </p>
        <p>
          And then.. back to long distance.. with no idea when we were going to
          meet next😢
        </p>
      </div>
      <Player url={jan} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
