import React, { useEffect,useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/apr/1.jpeg";
import im2 from "../../assets/images/apr/2.jpeg";
import im3 from "../../assets/images/apr/3.jpeg";
import im4 from "../../assets/images/apr/4.jpeg";
import im5 from "../../assets/images/apr/5.jpeg";
import im6 from "../../assets/images/apr/6.jpeg";
import im7 from "../../assets/images/apr/7.jpeg";
import im8 from "../../assets/images/apr/8.jpeg";
import im9 from "../../assets/images/apr/9.jpeg";
import im10 from "../../assets/images/apr/10.jpeg";
import im11 from "../../assets/images/apr/11.jpeg";
import im12 from "../../assets/images/apr/12.jpeg";
import im13 from "../../assets/images/apr/13.jpeg";
import im14 from "../../assets/images/apr/14.jpeg";

import { Link } from "react-router-dom";

import apr from "../../assets/music/apr.mp3"; //Jab koi baat bigad jaaye
export default function April() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);

  return (
    <div className="month_main">
      <div className="month_title">April💋</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to April...</p>
        <p className="month_text_big">
          <i>4 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          The month of april was just filled with lots more love, so many
          beautiful moments and quality time spent with each other. ❣️❣️
        </p>
        <p>
          We attended our first Revels together, from Stand Up Comedy, to
          memorable concerts we saw it all together. We got to spend soo much
          quality time with each other, which was all that we had been craving
          for after months of being apart... and also on soo manyy amazing
          dates. 😘😘😘😘
        </p>
        <p>
          Each day that we spent together, every new experience that we had
          together, I could feel our love growing stronger through it all, I
          could feel our bond strengthening with each passing day! 🌍💋🌍💋🌍💋
        </p>
      </div>
      <Player url={apr} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
          <div>
            <img src={im9} className="month_img" />
          </div>
          <div>
            <img src={im10} className="month_img" />
          </div>
          <div>
            <img src={im11} className="month_img" />
          </div>
          <div>
            <img src={im12} className="month_img" />
          </div>
          <div>
            <img src={im13} className="month_img" />
          </div>
          <div>
            <img src={im14} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
