import React, { useState, useEffect } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Player from "./Player";
import song from "./assets/music/main.mp3";
export default function Main() {
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    localStorage.removeItem("reloadCount");
    const reloadCount = localStorage.getItem("reloadCountMain");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCountMain", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  return (
    <div className="main">
      <div className="title">Happy 20th Birthday Trisha!!</div>

      <div className="sub_text2">
        <p>Maybe after your last birthday you expected this 🤭.</p>
        <p>
          Maybe because I've done this for you before too, it might not be as
          special...🥺🥺🥺
        </p>
        <p>
          But here's a highlight of everything starting from you last
          birthday... leading up to this one... <br />
        </p>
      </div>
      <Player url={song} />
      <div className="sub_text">
        Let's take a look at everything we've been through together darling,
        since your last birthday...❤️💗❤️💗
      </div>

      <div className="calender-box">
        <Link to={"/sept"}>
          <div className="c-month">
            <div>September ❤️</div>
          </div>
        </Link>
        <Link to={"/oct"}>
          <div className="c-month">
            <div>October 💗</div>
          </div>
        </Link>
        <Link to={"/nov"}>
          <div className="c-month">
            <div>November 💓</div>
          </div>
        </Link>
        <Link to={"/dec"}>
          <div className="c-month">
            <div>December 💘</div>
          </div>
        </Link>
        <Link to={"/jan"}>
          <div className="c-month">
            <div>January ❣️</div>
          </div>
        </Link>
        <Link to={"/feb"}>
          <div className="c-month">
            <div>February 🌍</div>
          </div>
        </Link>
        <Link to={"/mar"}>
          <div className="c-month">
            <div>March 💞</div>
          </div>
        </Link>
        <Link to={"/apr"}>
          <div className="c-month">
            <div>April 💋</div>
          </div>
        </Link>
        <Link to={"/may"}>
          <div className="c-month">
            <div>May 😘</div>
          </div>
        </Link>
        <Link to={"/jun"}>
          <div className="c-month">
            <div>June 💍</div>
          </div>
        </Link>
        <Link to={"/jul"}>
          <div className="c-month">
            <div>July 💝</div>
          </div>
        </Link>
        <Link to={"/aug"}>
          <div className="c-month">
            <div>August ❤️</div>
          </div>
        </Link>
      </div>
      <div className="sub_text2">
        PS. Since ab hum pura time saath rehte hai, you have nooo idea how
        difficult it was to work on this tum se chhupa chhupa ke... 🥺🥺❤️❤️💍💍
        <br />
        <br />
        I know that you hate my designing and UI sense, toh I am sorry if this
        website is not upto your expectations..
        <br />
        But I really hope you like it💞💞💞
      </div>
      {/*
      Songs
      Main- Happy Birthday
      Sept- Jab koi baat bigad jaaye
      Oct- I love you
      Nov-Kesariya
      Dec- Tujhe Kitna chahne lage hai
      Jan- Tumse hi Tumse
      Feb- Raataan Lambiyan
      Mar- Tujh mein rab dikhta hai
      Apri- You
      May- Tere sang yaara
      Jun- Humdard
      Jul- All of Me
      Aug- Humsafae
       */}
      <div className="sub_text1">I LOVE YOU!❤️</div>
    </div>
  );
}
