import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/jul/1.jpeg";
import im2 from "../../assets/images/jul/2.jpeg";
import im3 from "../../assets/images/jul/3.jpeg";
import im4 from "../../assets/images/jul/4.jpeg";
import im5 from "../../assets/images/jul/5.jpeg";
import im6 from "../../assets/images/jul/6.jpeg";
import im7 from "../../assets/images/jul/7.jpeg";
import im8 from "../../assets/images/jul/8.jpeg";

import { Link } from "react-router-dom";

import jul from "../../assets/music/jul.mp3";

export default function July() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);



  return (
    <div className="month_main">
      <div className="month_title">July💝</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to July...</p>
        <p className="month_text_big">
          <i>1 Month To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          For the first couple of weeks of July, we were back in a long distance
          relationship🥺🥺🥺
        </p>
        <p>
          Being back in campuss without you there with me na, was HELL! Spending
          the entire day in campus all alone, without you by my side, I just
          felt horrible, all alone.😢😢
        </p>
        <p>
          And then... Finally you were back! Me standing outside the airport
          with a bouquet of roses in my hand, couldn't wait to see you again, hold you
          in my arms again! 💞💞💞
        </p>
        <p>
          And then, lots of time together, lots of random trips, multiple trips
          to the beach, every moment with you just felt so amazing.🥺🌍
        </p>
        <p>
          And then finally, my birthday... my first birthday spent physically
          with you,&nbsp;&nbsp;THANK YOU for making it so so so so special
          babbyy!! ❣️❣️❣️❣️❣️❣️
        </p>
      </div>
      <Player url={jul} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
