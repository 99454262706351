import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/dec/1.jpeg";
import im2 from "../../assets/images/dec/2.jpeg";
import im3 from "../../assets/images/dec/3.jpeg";
import im4 from "../../assets/images/dec/4.jpeg";
import im5 from "../../assets/images/dec/5.jpeg";

import { Link } from "react-router-dom";

import dec from "../../assets/music/dec.mp3"; //Jab koi baat bigad jaaye
export default function December() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);



  return (
    <div className="month_main">
      <div className="month_title">December💘</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to December...</p>
        <p className="month_text_big">
          <i>8 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          Another months of long distance babe, another month far away from each
          other, craving to be with each other🥺🥺
        </p>
        <p>
          As time progressed we just got more desparate to be with each other
          babyy, I know I was almost going to come in December, but was not able
          to💔
        </p>
        <p>
          But I know babbyy that it was our love that got us through this tough
          phase of being sooo far apart from one another, 
          our love that continued to grow every single day, through all the
          difficulties and hardships! 💞💞💞
        </p>
      </div>
      <Player url={dec} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
