import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/nov/1.jpeg";
import im2 from "../../assets/images/nov/2.jpeg";
import im3 from "../../assets/images/nov/3.jpeg";
import im4 from "../../assets/images/nov/4.jpeg";
import im5 from "../../assets/images/nov/5.jpeg";
import im6 from "../../assets/images/nov/6.jpeg";
import im7 from "../../assets/images/nov/7.jpeg";
import im8 from "../../assets/images/nov/8.jpeg";
import im9 from "../../assets/images/nov/9.jpeg";
import im10 from "../../assets/images/nov/10.jpeg";
import im11 from "../../assets/images/nov/11.jpeg";
import im12 from "../../assets/images/nov/12.jpeg";
import im13 from "../../assets/images/nov/13.jpeg";
import im14 from "../../assets/images/nov/14.jpeg";
import im15 from "../../assets/images/nov/15.jpeg";

import { Link } from "react-router-dom";

import nov from "../../assets/music/nov.mp3"; //Kesariya
export default function November() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">November💓</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to November...</p>
        <p className="month_text_big">
          <i>9 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>What an eventful month for us!!!</p>
        <p>
          From our first Diwali together (well kind of together 🥺) to us
          working professionally together for the first time! (Exodus)
        </p>

        <p>
          And finalllyyyyyy we met!! 22nd November, the day I saw you for the
          first time, I hugged you for the first time!💞💞💞💞💞💞💞 <br />
          Those 3 days were so magical... from walks at endpoint to just hours
          of finally getting to be with each other!!
        </p>

        <p>
          I laid eyes on you for the first time, I was with you for the first
          time, and I had already imagined the rest of my life with you.
          😘❣️😘❣️
        </p>
      </div>
      <Player url={nov} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
          <div>
            <img src={im9} className="month_img" />
          </div>
          <div>
            <img src={im10} className="month_img" />
          </div>
          <div>
            <img src={im11} className="month_img" />
          </div>
          <div>
            <img src={im12} className="month_img" />
          </div>
          <div>
            <img src={im13} className="month_img" />
          </div>
          <div>
            <img src={im14} className="month_img" />
          </div>
          <div>
            <img src={im15} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
