import React, { useState, useEffect } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/feb/1.jpeg";
import im2 from "../../assets/images/feb/2.jpeg";
import im3 from "../../assets/images/feb/3.jpeg";
import im4 from "../../assets/images/feb/4.jpeg";
import im5 from "../../assets/images/feb/5.jpeg";
import im6 from "../../assets/images/feb/6.jpeg";
import im7 from "../../assets/images/feb/7.jpeg";
import im8 from "../../assets/images/feb/8.jpeg";

import { Link } from "react-router-dom";

import feb from "../../assets/music/feb.mp3"; //Jab koi baat bigad jaaye
export default function February() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);



  return (
    <div className="month_main">
      <div className="month_title">February🌍</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to February...</p>
        <p className="month_text_big">
          <i>6 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          In February, we finally got the news that we are going to come back to
          campus, coming back to each other!
        </p>
        <p>
          FINALLYYYY!! No more of being satisfied with online conversations...
          no more of being satisfied with just a couple of hours with each
          other.. now we were going to get to spend all our time with each
          other!! 🥺❤️🥺❤️
        </p>
        <p>
          Entire Feb just went by with us being desparate and excited to come to
          college and be with each other.. preparing for college. <br />
        </p>
      </div>
      <Player url={feb} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
